/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "core-js/stable";
import "regenerator-runtime/runtime";

// Allow local time to be used in erb templates:
import LocalTime from "local-time";
LocalTime.start();

// Automatically close other submenus in mobile layout when a submenu opens:
window.onload = function () {
  const subMenuSlugs = ["games", "gallery", "more", "beyond"];
  const subMenuCheckboxes = [];
  subMenuSlugs.forEach((subMenuSlug) => {
    subMenuCheckboxes.push(document.getElementById(`${subMenuSlug}-checkbox`));
  });

  function closeOtherSubMenus(event) {
    subMenuCheckboxes.forEach((subMenuCheckbox) => {
      if (subMenuCheckbox.id !== event.srcElement.id) {
        subMenuCheckbox.checked = false;
      }
    });
  }

  subMenuCheckboxes.forEach((subMenuCheckbox) => {
    subMenuCheckbox.addEventListener("click", closeOtherSubMenus);
  });
};
